import React, { useContext } from 'react';
import styled from 'styled-components';
import { MouseContext } from '../context/mouseContext';
import useMousePosition from '../hooks/useMousePosition';

import exploreCursor from '../images/home/cursors/explore-cursor.svg';
import nextCursorBlue from '../images/home/cursors/next-cursor-blue.svg';
import nextCursorWhite from '../images/home/cursors/next-cursor-white.svg';

const CustomCursor = styled.div`
    display: none;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 1px solid #fff;
    z-index: 1;
    transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
        0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67) width,
        0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67) height,
        0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
    user-select: none;
    pointer-events: none;
    z-index: 10000;
    top: 50%;
    left: 50%;
    transform: scale(1) translate(-50%, -50%);

    &.active {
        opacity: 1;
        transform: scale(0);
    }

    &.menu-active {
        opacity: 1;
        transform: scale(0);
    }

    &.hovered {
        opacity: 1;
    }

    &.next-slide {
        width: 90px;
        height: 90px;
        background: #ececec;
        border: #ececec;
        transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
            0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67) width,
            0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67) height,
            0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
        &:after {
            display: none;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            left: 0;
            right: 0;
            font-family: 'Source Serif Pro';
            font-size: 1.4rem;
            line-height: 1;
            text-align: center;
            font-style: italic;
        }
        &.slide-0,
        &.slide-2,
        &.slide-4 {
            background: #ececec;
            border-color: #ececec;
            color: #0e1828;
        }
        &.slide-1,
        &.slide-3 {
            background: #0e1828;
            border-color: #0e1828;
            color: #ececec;
        }
        &.slide-0 {
            &:after {
                display: block;
                content: url(${exploreCursor});
            }
        }
        &.slide-1,
        &.slide-3 {
            &:after {
                display: block;
                content: url(${nextCursorWhite});
            }
        }
        &.slide-2,
        &.slide-4 {
            &:after {
                display: block;
                content: url(${nextCursorBlue});
            }
        }
    }
    @media only screen and (min-width: 1024px) {
        display: block;
    }
`;

const Cursor = () => {
    const { cursorType } = useContext(MouseContext);
    const { x, y } = useMousePosition();

    return <CustomCursor className={cursorType} style={{ top: y + 'px', left: x + 'px' }} />;
};

export default Cursor;
