import React from 'react';
import useWindowDimensions from '../hooks/useWindowDimensions';
import MobileFooter from './Footer/mobileFooter';
import DesktopFooter from './Footer/desktopFooter';

const Footer = () => {
    const { width } = useWindowDimensions();
    const breakpoint = 1024;
    if (width < breakpoint) {
        return <MobileFooter />;
    } else {
        return <DesktopFooter />;
    }
};

export default Footer;
