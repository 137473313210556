import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Logo from '../logo';
import sendWhite from '../../images/icons/send-white.png';

const FooterWrapper = styled.footer`
    display: block;
    width: 100%;
    background: #0e1828;
`;
const OuterWrapper = styled.div`
    display: block;
    position: relative;
    width: 100%;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
`;
const InnerWrapper = styled.div`
    display: block;
    width: 100%;
    padding: 7rem 4rem;
`;
const Column = styled.div`
    display: block;
    width: 100%;
    margin-bottom: 4rem;
    .tertiary-links {
        display: block;
        width: 100%;
        font-size: 0;
        margin-bottom: 4rem;
        a {
            display: block;
            font-size: 1.1rem;
            line-height: 1.5;
            font-weight: 400;
            letter-spacing: 0.3em;
            text-transform: uppercase;
            text-decoration: none;
            color: #ececec;
            margin-bottom: 1.6rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .copyright-content {
        display: block;
        width: 100%;
        color: #ececec;
        font-size: 1.1rem;
        line-height: 1.5;
        font-weight: 400;
        margin: 0;
        p {
            margin: 0;
        }
    }
`;
const LogoWrapper = styled.div`
    display: block;
    width: 70px;
    height: 70px;
    margin-bottom: 1.5rem;
    svg {
        display: block;
        width: 100%;
        height: auto;
    }
`;
const ContentWrapper = styled.div`
    display: block;
    width: 100%;
    color: #fff;
    h3 {
        font-size: 1.6rem;
        line-height: 1.5;
        font-weight: 600;
    }
    p {
        font-size: 1.4rem;
        line-height: 1.5;
        font-weight: 300;
        margin-bottom: 4rem;
    }
    a.button {
        display: inline-block;
        padding: 1.5rem 2rem;
        background: #e2246e;
        color: #fff;
        font-family: 'Poppins';
        font-size: 1.1rem;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        border: none;
        border-radius: 25px;
        pointer-events: all;
        text-decoration: none;
        i {
            display: inline-block;
            width: 2.2rem;
            height: 2.2rem;
            background: url(${sendWhite}) center/contain no-repeat;
            vertical-align: middle;
            margin-left: 2rem;
        }
    }
`;
const NavigationContainer = styled.div`
    display: block;
    width: 100%;
    border-bottom: 2px solid #fff;
    overflow: hidden;
    height: 64px;
    &.open {
        height: auto;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            margin: 0 0 2rem 0;
            a {
                font-size: 1.4rem;
                line-height: 1.5;
                font-weight: 300;
                color: #fff;
                text-decoration: none;
            }
        }
    }
`;
const ButtonMenuToggle = styled.button`
    display: block;
    width: 100%;
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: 600;
    color: #fff;
    padding: 2rem 0;
    -webkit-appearance: none;
    appearance: none;
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
`;

class MobileFooter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            footerMenu1Open: false,
            footerMenu2Open: false,
        };

        this.toggleFooterMenu1 = this.toggleFooterMenu1.bind(this);
        this.toggleFooterMenu2 = this.toggleFooterMenu2.bind(this);
    }

    toggleFooterMenu1() {
        this.setState((state) => ({
            footerMenu1Open: !state.footerMenu1Open,
        }));
    }

    toggleFooterMenu2() {
        this.setState((state) => ({
            footerMenu2Open: !state.footerMenu2Open,
        }));
    }

    render() {
        return (
            <FooterWrapper>
                <OuterWrapper>
                    <InnerWrapper>
                        <Column className="fifty-fifty">
                            <LogoWrapper>
                                <Logo type="outline-white" />
                            </LogoWrapper>
                            <ContentWrapper>
                                <h3>Let's work together</h3>
                                <p>
                                    If you are looking to help your business achieve a change of
                                    speed, or direction, and accelerate your growth, we’re here to
                                    help
                                </p>
                                <a className="button" href="/contact">
                                    Send a message <i />
                                </a>
                            </ContentWrapper>
                        </Column>
                        <Column>
                            <NavigationContainer
                                className={this.state.footerMenu1Open ? 'open' : null}
                            >
                                <ButtonMenuToggle onClick={this.toggleFooterMenu1}>
                                    Navigate
                                </ButtonMenuToggle>
                                <ul>
                                    <li>
                                        <Link to="/about">About</Link>
                                    </li>
                                    <li>
                                        <Link to="/ecommerce">E-Commerce</Link>
                                    </li>
                                    <li>
                                        <Link to="/digital-marketing">Digital Marketing</Link>
                                    </li>
                                </ul>
                            </NavigationContainer>
                            <NavigationContainer
                                className={this.state.footerMenu2Open ? 'open' : null}
                            >
                                <ButtonMenuToggle onClick={this.toggleFooterMenu2}>
                                    Contact
                                </ButtonMenuToggle>
                                <ul>
                                    <li>
                                        <a href="mailto:info@digital-velocity.co.uk">
                                            info@digital-velocity.co.uk
                                        </a>
                                    </li>
                                    <li>
                                        <a href="tel:01134601202">0113 460 1202</a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://goo.gl/maps/TfAk2zyFso84GyKdA"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Get Directions
                                        </a>
                                    </li>
                                </ul>
                            </NavigationContainer>
                        </Column>
                        <Column>
                            <div className="social-media-icons"></div>
                            <div className="tertiary-links">
                                <Link to="/privacy-policy">Privacy Policy</Link>
                                <Link to="/cookies">Cookies</Link>
                                <Link to="/data-protection-policy">Data Protection</Link>
                            </div>
                            <div className="copyright-content">
                                <p>
                                    Digital Velocity LTD © 2021 | Registered in UK 09305069 | 8 Park
                                    Row, Leeds, LS1 5HD
                                </p>
                            </div>
                        </Column>
                    </InnerWrapper>
                </OuterWrapper>
            </FooterWrapper>
        );
    }
}

export default MobileFooter;
