/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import MouseContextProvider from '../context/mouseContext';
// import ContactFormPopupContextProvider from '../context/contactFormPopupContext';

import Header from './header';
import Footer from './footer';
// import ContactFormPopup from './popups/contactFormPopup';
import Cursor from './cursor';
import './layout.css';

const Layout = ({ children, ...props }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <MouseContextProvider>
            {/* <ContactFormPopupContextProvider> */}
            <Header
                siteTitle={data.site.siteMetadata?.title || `Digital Velocity`}
                page={props.path}
            />
            <main>{children}</main>
            {props.hideFooter !== true ? <Footer /> : null}
            {/* <ContactFormPopup /> */}
            {/* </ContactFormPopupContextProvider> */}
            <Cursor />
        </MouseContextProvider>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
