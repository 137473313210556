import React from 'react';
import styled from 'styled-components';
import TransitionLink from 'gatsby-plugin-transition-link';

const NavItem = styled(TransitionLink)`
    display: block;
    font-family: 'Poppins';
    font-size: 2.8rem;
    line-height: 1.1;
    font-weight: 600;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    margin-bottom: 2.4rem;
    max-width: 205px;
    overflow: hidden;
    :nth-child(1) {
        span.title {
            transition-delay: 0s;
        }
    }
    :nth-child(2) {
        span.title {
            transition-delay: 0.2s;
        }
    }
    :nth-child(3) {
        span.title {
            transition-delay: 0.35s;
        }
    }
    :nth-child(4) {
        span.title {
            transition-delay: 0.5s;
        }
    }
    :nth-child(5) {
        span.title {
            transition-delay: 0.65s;
        }
    }
    :last-child {
        margin-bottom: 0;
    }
    @media only screen and (min-width: 1024px) {
        font-size: 6rem;
        line-height: 1.5;
        max-width: 100%;
        margin-bottom: 0.8rem;
        &:hover {
            color: #5fddeb;
        }
    }
`;

const NavItemSpan = styled.div`
    display: block;
    span.title {
        display: block;
        transition: 0.3s transform ease-out, 0.3s opacity ease-out;
        transform: ${(props) => (props.open ? 'translateY(0%)' : 'translateY(100%)')};
        opacity: ${(props) => (props.open ? '1' : '0')};
    }
    .hover-state {
        display: none;
        position: relative;
        overflow: hidden;
        font-size: 1.6rem;
        line-height: 1.375;
        span {
            display: block;
            transform: translateY(100%);
            font-family: 'Source serif pro';
            font-size: 1.6rem;
            line-height: 1.375;
            font-style: italic;
            text-transform: none;
            letter-spacing: 0;
            color: #fff;
            transition: 0.3s transform ease-out;
        }
    }
    &:hover {
        .hover-state {
            span {
                transform: translateY(0);
                transition: 0.3s transform ease-out;
            }
        }
    }
    @media only screen and (min-width: 1024px) {
        .hover-state {
            display: block;
        }
    }
`;

const NavbarLinks = ({ open }) => {
    return (
        <>
            <NavItem
                to="/"
                exit={{
                    length: 1,
                }}
                entry={{
                    length: 1,
                }}
            >
                <NavItemSpan open={open}>
                    <span className="title">Home</span>
                    <div className="hover-state">
                        <span>Your Business, Accelerated...</span>
                    </div>
                </NavItemSpan>
            </NavItem>
            <NavItem
                to="/about"
                exit={{
                    length: 1,
                }}
                entry={{
                    length: 1,
                }}
            >
                <NavItemSpan open={open}>
                    <span className="title">About us</span>
                    <div className="hover-state">
                        <span>We are problem solvers</span>
                    </div>
                </NavItemSpan>
            </NavItem>
            <NavItem
                to="/ecommerce"
                exit={{
                    length: 1,
                }}
                entry={{
                    length: 1,
                }}
            >
                <NavItemSpan open={open}>
                    <span className="title">E-commerce</span>
                    <div className="hover-state">
                        <span>Accelerate your business</span>
                    </div>
                </NavItemSpan>
            </NavItem>
            <NavItem
                to="/digital-marketing"
                exit={{
                    length: 1,
                }}
                entry={{
                    length: 1,
                }}
            >
                <NavItemSpan open={open}>
                    <span className="title">Digital Marketing</span>
                    <div className="hover-state">
                        <span>Moving forward with you</span>
                    </div>
                </NavItemSpan>
            </NavItem>
            <NavItem
                to="/contact"
                exit={{
                    length: 1,
                }}
                entry={{
                    length: 1,
                }}
            >
                <NavItemSpan open={open}>
                    <span className="title">Contact</span>
                    <div className="hover-state">
                        <span>Let's work together</span>
                    </div>
                </NavItemSpan>
            </NavItem>
        </>
    );
};

export default NavbarLinks;
