import React, { createContext, useState } from 'react';

export const HomepageSlideContext = createContext({
    activeSlide: '',
    slideChangeHandler: () => {},
});

const HomepageSlideContextProvider = (props) => {
    const [activeSlide, setActiveSlide] = useState(0);

    const slideChangeHandler = (activeSlide) => {
        setActiveSlide(activeSlide);
    };

    return (
        <HomepageSlideContext.Provider
            value={{
                activeSlide: activeSlide,
                slideChangeHandler: slideChangeHandler,
            }}
        >
            {props.children}
        </HomepageSlideContext.Provider>
    );
};

export default HomepageSlideContextProvider;
