import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link, useStaticQuery, graphql } from 'gatsby';

const Container = styled.div`
    display: flex;
    flex: 0 1 auto;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 205px;
    opacity: ${(props) => (props.open ? '1' : '0')};
    transition: 2s all ease-in-out;
    margin-top: 8rem;
    z-index: 50;
    @media only screen and (min-width: 1024px) {
        position: absolute;
        bottom: 4rem;
        right: 4rem;
        max-width: 100%;
        align-items: flex-end;
        justify-content: flex-end;
    }
`;
const Column = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    flex: 0 1 auto;
    width: 100%;
    @media only screen and (min-width: 1024px) {
        justify-content: flex-end;
    }
`;
const LinkList = styled.div`
    display: block;
    flex: 0 1 auto;
`;
const SocialList = styled.div`
    display: block;
    flex: 0 1 auto;
    width: 2rem;
    margin-left: 5rem;
`;

const NavItem = styled(Link)`
    display: block;
    font-family: 'Poppins';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    color: #ececec;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    text-decoration: none;
    margin-bottom: 1.6rem;
    :last-child {
        margin-bottom: 0;
    }
    @media only screen and (min-width: 1024px) {
        display: inline-block;
        margin: 0 1.6rem 0 0;
        &:last-child {
            margin: 0;
        }
    }
`;

const SocialItem = styled.a`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    margin-bottom: 2rem;
    :last-child {
        margin-bottom: 0;
    }
    .gatsby-image-wrapper {
        display: block;
        flex: 0 1 auto;
        width: 100%;
        height: auto;
    }
`;

const NavbarFooterLinks = ({ open }) => {
    const data = useStaticQuery(graphql`
        {
            instagramIcon: file(name: { eq: "instagram-icon-white" }, extension: { eq: "png" }) {
                childImageSharp {
                    gatsbyImageData(width: 40, quality: 100, placeholder: NONE, layout: CONSTRAINED)
                }
            }
            twitterIcon: file(name: { eq: "twitter-icon-white" }, extension: { eq: "png" }) {
                childImageSharp {
                    gatsbyImageData(width: 40, quality: 100, placeholder: NONE, layout: CONSTRAINED)
                }
            }
            linkedInIcon: file(name: { eq: "linkedin-icon-white" }, extension: { eq: "png" }) {
                childImageSharp {
                    gatsbyImageData(width: 40, quality: 100, placeholder: NONE, layout: CONSTRAINED)
                }
            }
        }
    `);

    return (
        <Container open={open}>
            <Column>
                <LinkList>
                    <NavItem to="/privacy-policy">Privacy Policy</NavItem>
                    <NavItem to="/cookies">Cookies</NavItem>
                    <NavItem to="/data-protection-policy">Data Protection</NavItem>
                </LinkList>
                <SocialList>
                    <SocialItem
                        href="https://www.linkedin.com/company/digital-velocity"
                        target="_blank"
                        data={data}
                    >
                        <GatsbyImage
                            image={data.linkedInIcon.childImageSharp.gatsbyImageData}
                            alt="LinkedIn"
                        />
                    </SocialItem>
                    <SocialItem
                        href="https://www.instagram.com/digitalvelocity/?hl=en"
                        target="_blank"
                        data={data}
                    >
                        <GatsbyImage
                            image={data.instagramIcon.childImageSharp.gatsbyImageData}
                            alt="Instagram"
                        />
                    </SocialItem>
                    <SocialItem href="https://twitter.com/dgtlvelocity" target="_blank">
                        <GatsbyImage
                            image={data.twitterIcon.childImageSharp.gatsbyImageData}
                            alt="Twitter"
                        />
                    </SocialItem>
                </SocialList>
            </Column>
        </Container>
    );
};

export default NavbarFooterLinks;
