import React, { useState, useContext, useEffect } from 'react';
import NavbarLinks from './NavbarLinks';
import NavbarFooterLinks from './NavbarFooterLinks';
import styled from 'styled-components';
import Logo from '../logo';
import LogoNoText from '../../images/logos/logo-no-text.png';
import { HomepageSlideContext } from '../../context/homepageSlideContext';

const Navigation = styled.nav`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: transparent;
    z-index: 45;
    pointer-events: ${(props) => (props.open ? 'all' : 'none')};
    visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.open ? '1' : '0')};
    transition: 1s all ease-in-out;
    @media only screen and (min-width: 1024px) {
        align-items: flex-start;
        padding: 0 15rem;
    }
`;
const Toggle = styled.div`
    display: flex;
    position: absolute;
    top: 5rem;
    right: 4rem;
    cursor: pointer;
    width: 30px;
    height: 30px;
    pointer-events: all;
    visibility: visible;
    opacity: 1;
    z-index: 50;
`;
const LogoNoTextContainer = styled.div`
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    z-index: 40;
    overflow: hidden;
    background: ${(props) => (props.open ? '#0E1828' : 'transparent')};
    transition: 0.5s all ease-in-out ${(props) => (props.open ? '0s' : '1s')};
    > .logo {
        display: block;
        position: absolute;
        top: 5rem;
        left: 4rem;
        width: 2.6rem;
        height: 2.6rem;
        background: url(${LogoNoText}) center/contain no-repeat;
        transform: ${(props) => (props.open ? 'scale(30)' : 'scale(0)')};
        transition: 0.5s all ease-in-out 0.5s;
        transform-origin: 45% 35%;
        opacity: 0.3;
    }
    @media only screen and (min-width: 1024px) {
        > .logo {
            transform: ${(props) => (props.open ? 'scale(80)' : 'scale(0)')};
            opacity: 1;
        }
    }
`;
const Navbox = styled.div`
    display: block;
    position: relative;
    flex: 0 1 auto;
    z-index: 50;
`;
const Hamburger = styled.div`
    display: block;
    position: relative;
    width: 3rem;
    height: 3rem;
    &.white {
        div.line-1,
        div.line-2,
        div.line-3 {
            background: #fff;
        }
    }
    &.blue {
        div.line-1,
        div.line-2,
        div.line-3 {
            background: ${(props) => (props.open ? '#fff' : '#0E1828')};
        }
    }
    div.line-1,
    div.line-2,
    div.line-3 {
        display: block;
        position: absolute;
        width: 30px;
        height: 2px;
        transition: all 0.3s linear;
        transform-origin: center;
    }

    div.line-1 {
        transform: ${(props) => (props.open ? 'rotate(-45deg)' : 'rotate(0deg)')};
        top: ${(props) => (props.open ? '14px' : '5px')};
    }

    div.line-2 {
        width: 20px;
        margin-left: auto;
        right: 0;
        top: 14px;
        opacity: ${(props) => (props.open ? '0' : '1')};
    }

    div.line-3 {
        transform: ${(props) => (props.open ? 'rotate(45deg)' : 'rotate(0deg)')};
        top: ${(props) => (props.open ? '14px' : '23px')};
    }
    &:hover {
        div.line-2 {
            width: 30px;
        }
    }
`;
const LogoWrapper = styled.div`
    display: block;
    position: absolute;
    top: 5rem;
    left: 4rem;
    width: auto;
    height: 2.6rem;
    font-size: 0;
    z-index: 50;
    opacity: ${(props) => (props.open ? '1' : '0')};
    transition: 2s all ease-in-out 0.5s;
    > svg {
        width: auto;
        height: 100%;
    }
`;
const Navbar = ({ ...props }) => {
    const { activeSlide } = useContext(HomepageSlideContext);
    const [navbarOpen, setNavbarOpen] = useState(false);

    useEffect(() => {
        if (navbarOpen) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = 'unset';
        }
    }, [navbarOpen]);

    return (
        <>
            <Toggle navbarOpen={navbarOpen} onClick={() => setNavbarOpen(!navbarOpen)}>
                <Hamburger
                    open={navbarOpen}
                    className={
                        activeSlide === 1 ||
                        activeSlide === 3 ||
                        props.page === '/about/' ||
                        props.page === '/digital-marketing/' ||
                        props.page === '/contact/'
                            ? 'blue'
                            : 'white'
                    }
                >
                    <div className="line-1"></div>
                    <div className="line-2"></div>
                    <div className="line-3"></div>
                </Hamburger>
            </Toggle>
            <LogoNoTextContainer open={navbarOpen}>
                <div className="logo"></div>
            </LogoNoTextContainer>
            <Navigation open={navbarOpen}>
                <LogoWrapper open={navbarOpen}>
                    <Logo type="full-horizontal-white-text" />
                </LogoWrapper>
                <Navbox open={navbarOpen}>
                    <NavbarLinks open={navbarOpen} />
                </Navbox>
                <NavbarFooterLinks open={navbarOpen} />
            </Navigation>
        </>
    );
};

export default Navbar;
