import React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% {
    transform: rotate(0deg) scale(1);
    transform-origin: center;
    filter: blur(0);
  }
  50% {
    filter: blur(2px);
    transform: rotate(1800deg) scale(1.5);
  }
  100% {
    transform: rotate(3600deg);
    filter: blur(0);
  }
`;

const SpinningLogo = styled.svg`
    &:hover {
        g.logo-spin {
            transform-origin: center;
            transform-box: fill-box;
            animation: ${spin} 2s linear forwards;
        }
    }
`;

const Logo = ({ type, pathColour }) => {
    if (type === 'full-horizontal') {
        return (
            <SpinningLogo x="0px" y="0px" viewBox="0 0 460 80">
                <g>
                    <g>
                        <path
                            fill={pathColour ? pathColour : '#0E1828'}
                            d="M126.3,22.4h6.4L119.3,58h-6.1L100,22.4h6.5l10,27.7L126.3,22.4z"
                        />
                        <path
                            fill={pathColour ? pathColour : '#0E1828'}
                            d="M152.5,58V22.4h22V28h-15.9v9.4H173v5.5h-14.4v9.4h15.9v5.6L152.5,58L152.5,58z"
                        />
                        <path
                            fill={pathColour ? pathColour : '#0E1828'}
                            d="M196.8,58V22.4h6v29.8h16.3v5.7L196.8,58z"
                        />
                        <path
                            fill={pathColour ? pathColour : '#0E1828'}
                            d="M254.2,21.7c9.3,0,18.1,6.9,18.1,18.5s-8.7,18.6-18.1,18.6s-18.1-6.9-18.1-18.6
              C236.1,28.5,244.8,21.7,254.2,21.7z M254.2,53c6,0,11.9-4.3,11.9-12.8c0-8.6-5.9-12.8-11.9-12.8c-6,0-11.9,4.2-11.9,12.8
              C242.2,48.8,248.2,53,254.2,53z"
                        />
                        <path
                            fill={pathColour ? pathColour : '#0E1828'}
                            d="M291.5,40.2c0-11.6,8.7-18.5,17.9-18.5c9,0,14.4,5.3,16,11.5l-5.7,1.9c-1.1-4.3-4.4-7.8-10.3-7.8
              c-6,0-11.7,4.3-11.7,12.9c0,8.3,5.7,12.7,11.8,12.7c2.4,0.1,4.7-0.7,6.7-2.1c1.9-1.4,3.3-3.4,3.9-5.8l5.5,1.8
              c-1.6,6-7,11.8-16.1,11.8C299.9,58.7,291.5,51.8,291.5,40.2z"
                        />
                        <path
                            fill={pathColour ? pathColour : '#0E1828'}
                            d="M346.7,58V22.4h6.1V58H346.7z"
                        />
                        <path
                            fill={pathColour ? pathColour : '#0E1828'}
                            d="M390.4,28.1V58h-6V28.1h-11.5v-5.7h29.2v5.7H390.4z"
                        />
                        <path
                            fill={pathColour ? pathColour : '#0E1828'}
                            d="M419.2,22.4h7.1l8.5,14.9l8.6-14.9h6.8l-12.5,20.5V58h-6V42.9L419.2,22.4z"
                        />
                        <g className="logo-spin">
                            <g>
                                <path
                                    fill="#5FDDEB"
                                    d="M17.6,16c-2.9,6.8-2.9,6.3-2.9,9.2c0,5.4-2.1,10.5,4.9,14.5c3.9-10,11.4-18.2,20.8-23.1 c2.7-4.7-2.4-5.8-15.5-4.4C22.4,13.1,20,14.4,17.6,16L17.6,16z"
                                />
                                <linearGradient
                                    id="SVGID_1_"
                                    gradientUnits="userSpaceOnUse"
                                    x1="68.3628"
                                    y1="-667.4697"
                                    x2="24.4877"
                                    y2="-665.6846"
                                    gradientTransform="matrix(1 0 0 -1 0 -642.1)"
                                >
                                    <stop offset="0.1" style={{ stopColor: '#874C98' }} />
                                    <stop offset="0.12" style={{ stopColor: '#8E4A97' }} />
                                    <stop offset="0.22" style={{ stopColor: '#A94192' }} />
                                    <stop offset="0.33" style={{ stopColor: '#BC3B8F' }} />
                                    <stop offset="0.43" style={{ stopColor: '#C7378D' }} />
                                    <stop offset="0.54" style={{ stopColor: '#CB368C' }} />
                                    <stop offset="0.75" style={{ stopColor: '#E32C59' }} />
                                    <stop offset="1" style={{ stopColor: '#E6273C' }} />
                                </linearGradient>
                                <path
                                    style={{ fill: 'url(#SVGID_1_)' }}
                                    d="M24.8,12.1c5.5,0.5,10.8,2,15.5,4.4c9.8,4.9,17.6,13.4,21.5,23.8c8.7,1.4,6.1-5.3,6.1-10.9 c0-2.8,0.1-2.6-4-13.1l-0.1-0.1c0,0-3.1-2.3-8.2-4.3C48.6,9.1,37.4,6.9,24.8,12.1z"
                                />
                                <linearGradient
                                    id="SVGID_2_"
                                    gradientUnits="userSpaceOnUse"
                                    x1="0.6881"
                                    y1="-674.0096"
                                    x2="36.2908"
                                    y2="-691.3616"
                                    gradientTransform="matrix(1 0 0 -1 0 -642.1)"
                                >
                                    <stop offset="0" style={{ stopColor: '#9462A5' }} />
                                    <stop offset="5.000000e-02" style={{ stopColor: '#7769AD' }} />
                                    <stop offset="0.1" style={{ stopColor: '#5C70B3' }} />
                                    <stop offset="0.16" style={{ stopColor: '#4974B8' }} />
                                    <stop offset="0.21" style={{ stopColor: '#3E77BB' }} />
                                    <stop offset="0.27" style={{ stopColor: '#3A78BC' }} />
                                    <stop offset="0.67" style={{ stopColor: '#2BB4E9' }} />
                                    <stop offset="1" style={{ stopColor: '#67C1BC' }} />
                                </linearGradient>
                                <path
                                    style={{ fill: 'url(#SVGID_2_)' }}
                                    d="M17.6,16C17,16.7,0.7,37.5,17.3,63.5 l0.1,0.1c0,0,3.1,2.4,8.3,4.5c24.1,1.9,22-1,15.4-4.6C31.2,58.6,23.5,50,19.6,39.6c-1.8-4.8-2.8-9.9-2.8-15.3 C16.8,21.5,17,18.7,17.6,16z"
                                />
                                <path
                                    fill="#E2246E"
                                    d="M41,63.5c-4.7,2.4-9.9,4-15.3,4.6c7.1,2.8,18,5,30.4-0.2c2.6-1.1,5.2-2.5,7.9-4.2l0,0 c16.3-24.7,1.1-46,0-47.3c0.5,2.7,0.8,5.4,0.8,8.2c0,5.6-1,10.9-2.9,15.8C58,50.4,50.5,58.7,41,63.5z"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </SpinningLogo>
        );
    } else if (type === 'full-horizontal-white-text') {
        return (
            <svg x="0px" y="0px" viewBox="0 0 440.3 62.1">
                <g>
                    <g>
                        <path
                            fill="#fff"
                            d="M116.4,13.4h6.4L109.4,49h-6.1L90.1,13.4h6.5l10,27.7L116.4,13.4z"
                        />
                        <path
                            fill="#fff"
                            d="M142.6,49V13.4h22V19h-15.9v9.4h14.4v5.5h-14.4v9.4h15.9v5.6h-22V49z"
                        />
                        <path fill="#fff" d="M186.9,49V13.4h6v29.8h16.3v5.7L186.9,49z" />
                        <path
                            fill="#fff"
                            d="M244.3,12.7c9.3,0,18.1,6.9,18.1,18.5s-8.7,18.6-18.1,18.6s-18.1-6.9-18.1-18.6 C226.2,19.5,234.9,12.7,244.3,12.7z M244.3,44c6,0,11.9-4.3,11.9-12.8c0-8.6-5.9-12.8-11.9-12.8s-11.9,4.2-11.9,12.8 C232.3,39.8,238.3,44,244.3,44z"
                        />
                        <path
                            fill="#fff"
                            d="M281.6,31.2c0-11.6,8.7-18.5,17.9-18.5c9,0,14.4,5.3,16,11.5l-5.7,1.9c-1.1-4.3-4.4-7.8-10.3-7.8 c-6,0-11.7,4.3-11.7,12.9c0,8.3,5.7,12.7,11.8,12.7c2.4,0.1,4.7-0.7,6.7-2.1c1.9-1.4,3.3-3.4,3.9-5.8l5.5,1.8 c-1.6,6-7,11.8-16.1,11.8C290,49.7,281.6,42.8,281.6,31.2z"
                        />
                        <path fill="#fff" d="M336.8,49V13.4h6.1V49H336.8z" />
                        <path
                            fill="#fff"
                            d="M380.5,19.1V49h-6V19.1H363v-5.7h29.2v5.7L380.5,19.1z"
                        />
                        <path
                            fill="#fff"
                            d="M409.3,13.4h7.1l8.5,14.9l8.6-14.9h6.8l-12.5,20.5V49h-6V33.9L409.3,13.4z"
                        />
                        <g>
                            <g>
                                <path
                                    fill="#5FDDEB"
                                    d="M7.7,7c-2.9,6.8-2.9,6.3-2.9,9.2c0,5.4-2.1,10.5,4.9,14.5c3.9-10,11.4-18.2,20.8-23.1 c2.7-4.7-2.4-5.8-15.5-4.4C12.5,4.1,10.1,5.4,7.7,7L7.7,7z"
                                />
                                <linearGradient
                                    id="SVGID_1_"
                                    gradientUnits="userSpaceOnUse"
                                    x1="58.4942"
                                    y1="740.4136"
                                    x2="14.6191"
                                    y2="738.6285"
                                    gradientTransform="matrix(1 0 0 1 0 -724)"
                                >
                                    <stop offset="0.1" style={{ stopColor: '#874C98' }} />
                                    <stop offset="0.12" style={{ stopColor: '#8E4A97' }} />
                                    <stop offset="0.22" style={{ stopColor: '#A94192' }} />
                                    <stop offset="0.33" style={{ stopColor: '#BC3B8F' }} />
                                    <stop offset="0.43" style={{ stopColor: '#C7378D' }} />
                                    <stop offset="0.54" style={{ stopColor: '#CB368C' }} />
                                    <stop offset="0.75" style={{ stopColor: '#E32C59' }} />
                                    <stop offset="1" style={{ stopColor: '#E6273C' }} />
                                </linearGradient>
                                <path
                                    style={{ fill: 'url(#SVGID_1_)' }}
                                    d="M14.9,3.1c5.5,0.5,10.8,2,15.5,4.4c9.8,4.9,17.6,13.4,21.5,23.8C60.6,32.7,58,26,58,20.4 c0-2.8,0.1-2.6-4-13.1l-0.1-0.1c0,0-3.1-2.3-8.2-4.3C38.7,0.1,27.5-2.1,14.9,3.1z"
                                />
                                <linearGradient
                                    id="SVGID_2_"
                                    gradientUnits="userSpaceOnUse"
                                    x1="-9.1901"
                                    y1="746.933"
                                    x2="26.4126"
                                    y2="764.285"
                                    gradientTransform="matrix(1 0 0 1 0 -724)"
                                >
                                    <stop offset="0" style={{ stopColor: '#9462A5' }} />
                                    <stop offset="5.000000e-02" style={{ stopColor: '#7769AD' }} />
                                    <stop offset="0.1" style={{ stopColor: '#5C70B3' }} />
                                    <stop offset="0.16" style={{ stopColor: '#4974B8' }} />
                                    <stop offset="0.21" style={{ stopColor: '#3E77BB' }} />
                                    <stop offset="0.27" style={{ stopColor: '#3A78BC' }} />
                                    <stop offset="0.67" style={{ stopColor: '#2BB4E9' }} />
                                    <stop offset="1" style={{ stopColor: '#67C1BC' }} />
                                </linearGradient>
                                <path
                                    style={{ fill: 'url(#SVGID_2_)' }}
                                    d="M7.7,7C7.1,7.7-9.2,28.5,7.4,54.5 l0.1,0.1c0,0,3.1,2.4,8.3,4.5c24.1,1.9,22-1,15.4-4.6C21.3,49.6,13.6,41,9.7,30.6c-1.8-4.8-2.8-9.9-2.8-15.3 C6.9,12.5,7.1,9.7,7.7,7z"
                                />
                                <path
                                    fill="#E2246E"
                                    d="M31.1,54.5c-4.7,2.4-9.9,4-15.3,4.6c7.1,2.8,18,5,30.4-0.2c2.6-1.1,5.2-2.5,7.9-4.2l0,0 c16.3-24.7,1.1-46,0-47.3c0.5,2.7,0.8,5.4,0.8,8.2c0,5.6-1,10.9-2.9,15.8C48.1,41.4,40.6,49.7,31.1,54.5z"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    } else if (type === 'full-stacked') {
        return (
            <svg
                width="440"
                height="274"
                viewBox="0 0 440 274"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0)">
                    <path
                        d="M33.2117 228.185H41.2088L24.3207 272.875H16.6372L0 228.185H8.18532L20.7299 262.965L33.2117 228.185Z"
                        fill="#0E1828"
                    />
                    <path
                        d="M66.094 272.875V228.185H93.692V235.241H73.6991V247.08H91.8103V253.948H73.6991V265.819H93.692V272.875H66.094Z"
                        fill="#0E1828"
                    />
                    <path
                        d="M121.667 272.875V228.185H129.24V265.677H149.719V272.859L121.667 272.875Z"
                        fill="#0E1828"
                    />
                    <path
                        d="M193.766 227.26C205.48 227.26 216.503 235.884 216.503 250.499C216.503 265.113 205.527 273.816 193.766 273.816C182.006 273.816 171.076 265.191 171.076 250.499C171.076 235.806 182.037 227.26 193.766 227.26ZM193.766 266.634C201.324 266.634 208.757 261.287 208.757 250.499C208.757 239.71 201.324 234.457 193.766 234.457C186.208 234.457 178.76 239.726 178.76 250.499C178.76 261.271 186.255 266.634 193.766 266.634Z"
                        fill="#0E1828"
                    />
                    <path
                        d="M240.698 250.561C240.698 236.01 251.597 227.26 263.2 227.26C274.537 227.26 281.28 233.94 283.303 241.749L276.184 244.132C274.851 238.722 270.696 234.379 263.2 234.379C255.705 234.379 248.445 239.726 248.445 250.561C248.445 261.02 255.642 266.571 263.263 266.571C266.262 266.666 269.206 265.747 271.618 263.963C274.031 262.18 275.772 259.635 276.56 256.739L283.491 259.013C281.468 266.571 274.663 273.816 263.263 273.816C251.22 273.816 240.698 265.129 240.698 250.561Z"
                        fill="#0E1828"
                    />
                    <path d="M310.07 272.875V228.185H317.769V272.875H310.07Z" fill="#0E1828" />
                    <path
                        d="M364.89 235.32V272.875H357.331V235.32H342.843V228.185H379.473V235.304L364.89 235.32Z"
                        fill="#0E1828"
                    />
                    <path
                        d="M401.065 228.185H410.019L420.728 246.892L431.501 228.185H440L424.319 253.948V272.875H416.761V253.964L401.065 228.185Z"
                        fill="#0E1828"
                    />
                    <g clipPath="url(#clip1)">
                        <path
                            d="M170.669 10.3689C186.682 11.7642 201.764 16.1494 215.451 22.993C243.822 37.0788 266.147 61.6625 277.442 91.5617C282.89 77.4759 285.814 62.1276 285.814 46.115C285.814 38.009 285.083 30.1024 283.621 22.4615C283.555 22.3286 283.488 22.2621 283.488 22.2621C283.488 22.2621 274.651 15.6179 259.768 9.8374C239.171 1.66498 206.88 -4.77994 170.669 10.3689Z"
                            fill="url(#paint0_linear)"
                        />
                        <path
                            d="M149.74 21.4649C148.278 29.1722 147.481 37.2117 147.481 45.3841C147.481 60.9317 150.271 75.8812 155.387 89.5684C166.616 60.6659 188.171 36.9928 215.413 22.9735C201.792 16.1299 186.682 11.7642 170.669 10.369C163.825 13.226 156.849 16.8803 149.74 21.4649Z"
                            fill="#5FDDEB"
                        />
                        <path
                            d="M149.74 21.4648C148.079 23.591 101.104 83.4557 148.942 158.536C149.009 158.669 149.075 158.801 149.208 158.934C149.208 158.934 158.112 165.844 173.194 171.824C189.007 170.163 204.023 165.579 217.578 158.602C189.14 144.383 166.683 119.8 155.52 89.7013C150.338 75.8148 147.547 60.8652 147.547 45.3177C147.481 37.2117 148.278 29.1722 149.74 21.4648Z"
                            fill="url(#paint1_linear)"
                        />
                        <path
                            d="M217.445 158.536C203.89 165.512 189.002 170.157 173.188 171.818C193.52 179.858 225.085 186.109 260.831 171.093C268.207 167.971 275.781 163.984 283.488 158.868C283.488 158.868 283.488 158.868 283.488 158.801C330.596 87.708 286.418 26.1864 283.495 22.2663C284.956 29.9072 285.814 38.009 285.814 46.0486C285.814 62.0612 282.824 77.4094 277.442 91.4952C266.28 120.597 244.819 144.45 217.445 158.536Z"
                            fill="#E2246E"
                        />
                    </g>
                </g>
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="284.741"
                        y1="48.0995"
                        x2="169.679"
                        y2="43.4202"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0.1" stopColor="#874C98" />
                        <stop offset="0.12" stopColor="#8E4A97" />
                        <stop offset="0.22" stopColor="#A94192" />
                        <stop offset="0.33" stopColor="#BC3B8F" />
                        <stop offset="0.43" stopColor="#C7378D" />
                        <stop offset="0.54" stopColor="#CB368C" />
                        <stop offset="0.75" stopColor="#E32C59" />
                        <stop offset="1" stopColor="#E6273C" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear"
                        x1="110.793"
                        y1="53.1387"
                        x2="201.587"
                        y2="97.4284"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9462A5" />
                        <stop offset="0.05" stopColor="#7769AD" />
                        <stop offset="0.1" stopColor="#5C70B3" />
                        <stop offset="0.16" stopColor="#4974B8" />
                        <stop offset="0.21" stopColor="#3E77BB" />
                        <stop offset="0.27" stopColor="#3A78BC" />
                        <stop offset="0.67" stopColor="#2BB4E9" />
                        <stop offset="1" stopColor="#67C1BC" />
                    </linearGradient>
                    <clipPath id="clip0">
                        <rect
                            width="440"
                            height="273"
                            fill="white"
                            transform="translate(0 0.815674)"
                        />
                    </clipPath>
                    <clipPath id="clip1">
                        <rect
                            width="180"
                            height="179"
                            fill="white"
                            transform="translate(126.685 1.2218)"
                        />
                    </clipPath>
                </defs>
            </svg>
        );
    } else if (type === 'outline') {
        return (
            <svg
                width="272"
                height="272"
                viewBox="0 0 272 272"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M66.6709 15.3823C56.4186 19.7014 45.979 25.1471 35.399 31.9544C35.399 31.9544 35.3521 32.0013 35.3521 32.0482"
                    stroke="#0E1828"
                    strokeWidth="10"
                    strokeMiterlimit="10"
                    strokeLinejoin="round"
                />
                <path
                    d="M236.045 33.5036C235.904 33.3158 235.811 33.2219 235.811 33.2219C235.811 33.2219 222.562 23.2693 200.326 14.5373"
                    stroke="#0E1828"
                    strokeWidth="10"
                    strokeMiterlimit="10"
                    strokeLinejoin="round"
                />
                <path
                    d="M34.1347 237.626C34.2752 237.814 34.3688 238.002 34.5093 238.19C34.5093 238.19 47.8981 248.565 70.4626 257.484"
                    stroke="#0E1828"
                    strokeWidth="10"
                    strokeMiterlimit="10"
                    strokeLinejoin="round"
                />
                <path
                    d="M201.918 256.499C212.966 251.851 224.342 245.842 235.858 238.19C235.858 238.143 235.905 238.143 235.905 238.096"
                    stroke="#0E1828"
                    strokeWidth="10"
                    strokeMiterlimit="10"
                    strokeLinejoin="round"
                />
                <path
                    d="M133.85 34.3017C113.298 24.0674 90.6399 17.4949 66.6709 15.3823C120.929 -7.33971 169.335 2.37818 200.326 14.5373"
                    stroke="#0E1828"
                    strokeWidth="10"
                    strokeMiterlimit="10"
                    strokeLinejoin="round"
                />
                <path
                    d="M34.1349 237.626C-37.5847 125.049 32.7773 35.1937 35.3052 32.0483C33.105 43.644 31.9346 55.6623 31.9346 67.9152C31.9346 91.2476 36.1479 113.594 43.8254 134.25"
                    stroke="#0E1828"
                    strokeWidth="10"
                    strokeMiterlimit="10"
                    strokeLinejoin="round"
                />
                <path
                    d="M226.776 137.255C234.922 116.082 239.369 93.0785 239.369 69.042C239.369 56.8829 238.245 45.0054 236.045 33.5036C240.399 39.3719 306.454 131.481 235.858 238.19"
                    stroke="#0E1828"
                    strokeWidth="10"
                    strokeMiterlimit="10"
                    strokeLinejoin="round"
                />
                <path
                    d="M201.917 256.499C148.268 279.033 100.986 269.597 70.4628 257.531C94.1509 254.996 116.528 248.142 136.799 237.673"
                    stroke="#0E1828"
                    strokeWidth="10"
                    strokeMiterlimit="10"
                    strokeLinejoin="round"
                />
                <path
                    d="M133.849 34.3017C176.403 55.5214 209.829 92.2804 226.776 137.255C210.016 180.774 177.761 216.5 136.752 237.673C94.1038 216.36 60.6315 179.413 43.8252 134.25C60.6783 90.9659 92.9334 55.3806 133.849 34.3017Z"
                    stroke="#0E1828"
                    strokeWidth="10"
                    strokeMiterlimit="10"
                    strokeLinejoin="round"
                />
            </svg>
        );
    } else if (type === 'outline-white') {
        return (
            <svg
                width="272"
                height="272"
                viewBox="0 0 272 272"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M66.6709 15.3823C56.4186 19.7014 45.979 25.1471 35.399 31.9544C35.399 31.9544 35.3521 32.0013 35.3521 32.0482"
                    stroke="#fff"
                    strokeWidth="10"
                    strokeMiterlimit="10"
                    strokeLinejoin="round"
                />
                <path
                    d="M236.045 33.5036C235.904 33.3158 235.811 33.2219 235.811 33.2219C235.811 33.2219 222.562 23.2693 200.326 14.5373"
                    stroke="#fff"
                    strokeWidth="10"
                    strokeMiterlimit="10"
                    strokeLinejoin="round"
                />
                <path
                    d="M34.1347 237.626C34.2752 237.814 34.3688 238.002 34.5093 238.19C34.5093 238.19 47.8981 248.565 70.4626 257.484"
                    stroke="#fff"
                    strokeWidth="10"
                    strokeMiterlimit="10"
                    strokeLinejoin="round"
                />
                <path
                    d="M201.918 256.499C212.966 251.851 224.342 245.842 235.858 238.19C235.858 238.143 235.905 238.143 235.905 238.096"
                    stroke="#fff"
                    strokeWidth="10"
                    strokeMiterlimit="10"
                    strokeLinejoin="round"
                />
                <path
                    d="M133.85 34.3017C113.298 24.0674 90.6399 17.4949 66.6709 15.3823C120.929 -7.33971 169.335 2.37818 200.326 14.5373"
                    stroke="#fff"
                    strokeWidth="10"
                    strokeMiterlimit="10"
                    strokeLinejoin="round"
                />
                <path
                    d="M34.1349 237.626C-37.5847 125.049 32.7773 35.1937 35.3052 32.0483C33.105 43.644 31.9346 55.6623 31.9346 67.9152C31.9346 91.2476 36.1479 113.594 43.8254 134.25"
                    stroke="#fff"
                    strokeWidth="10"
                    strokeMiterlimit="10"
                    strokeLinejoin="round"
                />
                <path
                    d="M226.776 137.255C234.922 116.082 239.369 93.0785 239.369 69.042C239.369 56.8829 238.245 45.0054 236.045 33.5036C240.399 39.3719 306.454 131.481 235.858 238.19"
                    stroke="#fff"
                    strokeWidth="10"
                    strokeMiterlimit="10"
                    strokeLinejoin="round"
                />
                <path
                    d="M201.917 256.499C148.268 279.033 100.986 269.597 70.4628 257.531C94.1509 254.996 116.528 248.142 136.799 237.673"
                    stroke="#fff"
                    strokeWidth="10"
                    strokeMiterlimit="10"
                    strokeLinejoin="round"
                />
                <path
                    d="M133.849 34.3017C176.403 55.5214 209.829 92.2804 226.776 137.255C210.016 180.774 177.761 216.5 136.752 237.673C94.1038 216.36 60.6315 179.413 43.8252 134.25C60.6783 90.9659 92.9334 55.3806 133.849 34.3017Z"
                    stroke="#fff"
                    strokeWidth="10"
                    strokeMiterlimit="10"
                    strokeLinejoin="round"
                />
            </svg>
        );
    } else if (type === 'colour') {
        return (
            <svg
                width="271"
                height="270"
                viewBox="0 0 271 270"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0)">
                    <path
                        d="M66.6462 14.3957C90.6652 16.4886 113.289 23.0664 133.82 33.3318C176.376 54.4605 209.863 91.3361 226.806 136.185C234.978 115.056 239.363 92.0337 239.363 68.0148C239.363 55.8558 238.267 43.9958 236.075 32.5345C235.975 32.3352 235.875 32.2355 235.875 32.2355C235.875 32.2355 222.62 22.2691 200.295 13.5984C169.4 1.33973 120.963 -8.32765 66.6462 14.3957Z"
                        fill="url(#paint0_linear)"
                    />
                    <path
                        d="M35.2526 31.0395C33.06 42.6005 31.864 54.6598 31.864 66.9185C31.864 90.2398 36.0499 112.664 43.724 133.195C60.5672 89.8411 92.9 54.3315 133.762 33.3024C113.331 23.0371 90.6656 16.4886 66.6467 14.3957C56.3813 18.6812 45.9166 24.1627 35.2526 31.0395Z"
                        fill="#5FDDEB"
                    />
                    <path
                        d="M35.2526 31.0395C32.761 34.2288 -37.7013 124.026 34.0566 236.646C34.1563 236.845 34.256 237.044 34.4553 237.244C34.4553 237.244 47.8102 247.609 70.4339 256.579C94.1539 254.087 116.678 247.21 137.009 236.745C94.3532 215.417 60.6668 178.542 43.9233 133.394C36.1496 112.564 31.9637 90.1401 31.9637 66.8188C31.864 54.6598 33.06 42.6005 35.2526 31.0395Z"
                        fill="url(#paint1_linear)"
                    />
                    <path
                        d="M136.81 236.646C116.478 247.11 94.1455 254.078 70.4255 256.569C100.923 268.629 148.271 278.006 201.89 255.482C212.953 250.798 224.314 244.818 235.875 237.144C235.875 237.144 235.875 237.144 235.875 237.044C306.537 130.404 240.27 38.1219 235.885 32.2417C238.077 43.703 239.363 55.8558 239.363 67.9151C239.363 91.9341 234.879 114.956 226.806 136.085C210.062 179.738 177.871 215.517 136.81 236.646Z"
                        fill="#E2246E"
                    />
                </g>
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="237.754"
                        y1="70.9915"
                        x2="65.1606"
                        y2="63.9725"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0.1" stopColor="#874C98" />
                        <stop offset="0.12" stopColor="#8E4A97" />
                        <stop offset="0.22" stopColor="#A94192" />
                        <stop offset="0.33" stopColor="#BC3B8F" />
                        <stop offset="0.43" stopColor="#C7378D" />
                        <stop offset="0.54" stopColor="#CB368C" />
                        <stop offset="0.75" stopColor="#E32C59" />
                        <stop offset="1" stopColor="#E6273C" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear"
                        x1="-23.1683"
                        y1="78.5504"
                        x2="113.023"
                        y2="144.985"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9462A5" />
                        <stop offset="0.05" stopColor="#7769AD" />
                        <stop offset="0.1" stopColor="#5C70B3" />
                        <stop offset="0.16" stopColor="#4974B8" />
                        <stop offset="0.21" stopColor="#3E77BB" />
                        <stop offset="0.27" stopColor="#3A78BC" />
                        <stop offset="0.67" stopColor="#2BB4E9" />
                        <stop offset="1" stopColor="#67C1BC" />
                    </linearGradient>
                    <clipPath id="clip0">
                        <rect
                            width="270"
                            height="268.5"
                            fill="white"
                            transform="translate(0.67041 0.674969)"
                        />
                    </clipPath>
                </defs>
            </svg>
        );
    }
};

export default Logo;
