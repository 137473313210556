import React, { useContext } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link, useStaticQuery, graphql } from 'gatsby';
// import { ContactFormPopupContext } from '../../context/contactFormPopupContext';
import Logo from '../logo';
import sendWhite from '../../images/icons/send-white.png';

const FooterWrapper = styled.footer`
    display: block;
    width: 100%;
    background: #0e1828;
`;
const OuterWrapper = styled.div`
    display: block;
    position: relative;
    width: 100%;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
`;
const InnerWrapper = styled.div`
    display: block;
    width: 100%;
    padding: 7rem 4rem;
`;
const RowOne = styled.div`
    display: flex;
    flex-flow: row nowrap;
    .column {
        display: block;
        flex: 0 0 auto;
        width: 25%;
        padding: 0 1rem;
        &.half-width {
            width: 50%;
            display: flex;
            flex-flow: row nowrap;
        }
        &.nav-links {
            margin-left: auto;
        }
        .logo-wrapper {
            display: block;
            flex: 0 0 auto;
            width: 70px;
            height: 70px;
            margin-right: 80px;
            svg {
                display: block;
                width: 100%;
                height: auto;
            }
        }
        .content-wrapper {
            display: block;
            flex: 0 1 auto;
            color: #fff;
            h3 {
                font-size: 1.6rem;
                line-height: 1.5;
                font-weight: 600;
            }
            p {
                font-size: 1.4rem;
                line-height: 1.5;
                font-weight: 300;
                margin-bottom: 4rem;
            }
            button.send-a-message {
                display: inline-block;
                padding: 1.5rem 2rem;
                background: #e2246e;
                color: #fff;
                font-family: 'Poppins';
                font-size: 1.1rem;
                font-weight: 600;
                line-height: 1.5;
                letter-spacing: 0.3em;
                text-transform: uppercase;
                border: none;
                border-radius: 25px;
                pointer-events: all;
                i {
                    display: inline-block;
                    width: 2.2rem;
                    height: 2.2rem;
                    background: url(${sendWhite}) center/contain no-repeat;
                    vertical-align: middle;
                    margin-left: 2rem;
                }
            }
        }
        h3 {
            &.nav-title {
                display: block;
                font-size: 1.6rem;
                line-height: 1.875;
                font-weight: 600;
                color: #fff;
                margin-bottom: 1.5rem;
            }
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                margin: 0 0 2rem 0;
                a {
                    font-size: 1.4rem;
                    line-height: 1.5;
                    font-weight: 300;
                    color: #fff;
                    text-decoration: none;
                }
            }
        }
    }
`;
const RowTwo = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-top: 75px;
    .column {
        display: block;
        flex: 0 1 auto;
        padding: 0 1rem;
        &.social-column {
            width: 80px;
            margin-right: 70px;
        }
        &.tertiary-column {
            margin-left: auto;
        }
        .tertiary-links {
            display: block;
            flex: 0 1 auto;
            font-size: 0;
            a {
                display: inline-block;
                font-size: 1.1rem;
                line-height: 1.5;
                font-weight: 400;
                letter-spacing: 0.3em;
                text-transform: uppercase;
                text-decoration: none;
                color: #ececec;
                margin-right: 3.5rem;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        .copyright-content {
            display: block;
            color: #ececec;
            font-size: 1.1rem;
            line-height: 1.5;
            font-weight: 400;
            margin: 0;
            p {
                margin: 0;
            }
        }
        .social-media-icons {
            display: flex;
            flex-flow: row nowrap;
        }
    }
`;
const SocialItem = styled.a`
    display: flex;
    flex: 0 0 auto;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    margin-right: 2rem;
    :last-child {
        margin-right: 0;
    }
    .gatsby-image-wrapper {
        display: block;
        flex: 0 1 auto;
        width: 100%;
        height: auto;
    }
`;

const DesktopFooter = () => {
    const data = useStaticQuery(graphql`
        {
            instagramIcon: file(name: { eq: "instagram-icon-white" }, extension: { eq: "png" }) {
                childImageSharp {
                    gatsbyImageData(width: 40, quality: 100, placeholder: NONE, layout: CONSTRAINED)
                }
            }
            twitterIcon: file(name: { eq: "twitter-icon-white" }, extension: { eq: "png" }) {
                childImageSharp {
                    gatsbyImageData(width: 40, quality: 100, placeholder: NONE, layout: CONSTRAINED)
                }
            }
            linkedInIcon: file(name: { eq: "linkedin-icon-white" }, extension: { eq: "png" }) {
                childImageSharp {
                    gatsbyImageData(width: 40, quality: 100, placeholder: NONE, layout: CONSTRAINED)
                }
            }
        }
    `);

    // const { popupChangeHandler } = useContext(ContactFormPopupContext);

    return (
        <FooterWrapper>
            <OuterWrapper>
                <InnerWrapper>
                    <RowOne>
                        <div className="column half-width">
                            <div className="logo-wrapper">
                                <Logo type="outline-white" />
                            </div>
                            <div className="content-wrapper">
                                <h3>Let's work together</h3>
                                <p>
                                    If you are looking to help your business achieve a change of
                                    speed, or direction, and accelerate your growth, we’re here to
                                    help
                                </p>
                                {/* <button
                                    className="send-a-message"
                                    onClick={() => popupChangeHandler(true)}
                                >
                                    Send a message <i></i>
                                </button> */}
                            </div>
                        </div>
                        <div className="column nav-links">
                            <h3 className="nav-title">Navigation</h3>
                            <ul>
                                <li>
                                    <Link to="/about">About</Link>
                                </li>
                                <li>
                                    <Link to="/">Careers</Link>
                                </li>
                                <li>
                                    <Link to="/ecommerce">E-Commerce</Link>
                                </li>
                                <li>
                                    <Link to="/digital-marketing">Digital Marketing</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="column">
                            <h3 className="nav-title">Contact</h3>
                            <ul>
                                <li>
                                    <a href="mailto:info@digital-velocity.co.uk">
                                        info@digital-velocity.co.uk
                                    </a>
                                </li>
                                <li>
                                    <a href="tel:01134601202">0113 460 1202</a>
                                </li>
                                <li>
                                    <a
                                        href="https://goo.gl/maps/TfAk2zyFso84GyKdA"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Get Directions
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </RowOne>
                    <RowTwo>
                        <div className="column social-column">
                            <div className="social-media-icons">
                                <SocialItem
                                    href="https://www.linkedin.com/company/digital-velocity"
                                    target="_blank"
                                >
                                    <GatsbyImage
                                        image={data.linkedInIcon.childImageSharp.gatsbyImageData}
                                        alt="LinkedIn"
                                    />
                                </SocialItem>
                                <SocialItem
                                    href="https://www.instagram.com/digitalvelocity/?hl=en"
                                    target="_blank"
                                >
                                    <GatsbyImage
                                        image={data.instagramIcon.childImageSharp.gatsbyImageData}
                                        alt="Instagram"
                                    />
                                </SocialItem>
                                <SocialItem href="https://twitter.com/dgtlvelocity" target="_blank">
                                    <GatsbyImage
                                        image={data.twitterIcon.childImageSharp.gatsbyImageData}
                                        alt="Twitter"
                                    />
                                </SocialItem>
                            </div>
                        </div>
                        <div className="column">
                            <div className="copyright-content">
                                <p>
                                    Digital Velocity LTD © 2021 | Registered in UK 09305069 | 8 Park
                                    Row, Leeds, LS1 5HD
                                </p>
                            </div>
                        </div>
                        <div className="column tertiary-column">
                            <div className="tertiary-links">
                                <Link to="/privacy-policy">Privacy Policy</Link>
                                <Link to="/cookies">Cookies</Link>
                                <Link to="/data-protection-policy">Data Protection</Link>
                            </div>
                        </div>
                    </RowTwo>
                </InnerWrapper>
            </OuterWrapper>
        </FooterWrapper>
    );
};

export default DesktopFooter;
