import React, { useContext } from 'react';
import Navbar from './NavBar/Navbar';
import styled from 'styled-components';
import { HomepageSlideContext } from '../context/homepageSlideContext';

import Logo from './logo';

const LogoWrapper = styled.div`
    display: block;
    position: absolute;
    top: 5rem;
    left: 2rem;
    width: auto;
    height: 3.2rem;
    font-size: 0;
    z-index: 10;
    a {
        display: block;
        width: auto;
        height: 3.2rem;
        > svg {
            display: block;
            width: auto;
            height: 100%;
            overflow: visible;
            path {
                transition: 0.5s fill ease-out;
            }
        }
    }
    @media only screen and (min-width: 1024px) {
        left: 4rem;
    }
`;

const Header = ({ ...props }) => {
    const { activeSlide } = useContext(HomepageSlideContext);
    return (
        <>
            <header>
                <LogoWrapper logo={props.page}>
                    <a href="/">
                        <Logo
                            type="full-horizontal"
                            pathColour={
                                activeSlide === 1 ||
                                activeSlide === 3 ||
                                props.page === '/about/' ||
                                props.page === '/digital-marketing/' ||
                                props.page === '/contact/'
                                    ? '#0E1828'
                                    : '#ffffff'
                            }
                        />
                    </a>
                </LogoWrapper>
            </header>
            <Navbar page={props.page} />
        </>
    );
};

export default Header;
